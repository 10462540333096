import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { StyledAppBar, StyledToolbar } from './styled';
import Box from '@mui/material/Box';
import { ReactComponent as AppLogo } from './../../assets/icons/app-logo.svg';
import { ReactComponent as PersonIcon } from './../../assets/icons/person.svg';
import { Link, useLocation } from 'react-router-dom';
import DropdownWithIcon from './../DropdownWithIcon';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'src/redux/auth/service';
import { ClientSelectors } from 'src/redux/client/selectors';
import useAppDispatch from 'src/hooks/useAppDispatch';
import {
    fetchClientRepositories,
    fetchProjectQuests,
    fetchProjectWorkTimeReview,
    selectProject
} from 'src/redux/client/service';
import { isAdmin } from 'src/redux/auth/service';
import { RoutesEnum } from 'src/types/navigation';
import { fetchTemplates } from 'src/redux/delegate/service';

export type Props = {
    children: React.ReactNode;
};

const Navbar: FunctionComponent<Props> = ({ children }) => {
    const admin = isAdmin();
    const [prjectsSubListSelected, setPrjectsSubListSelected] =
        useState<boolean>(false);
    const {
        selectedProjectId,
        isLoading,
        repositories,
        questsRowsPerPage,
        questsSearchQuery,
        questsStatusFilter
    } = ClientSelectors();
    const location = useLocation().pathname;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleSignout = () => {
        signOut().then(() => {
            navigate('/login');
        });
    };

    const menuItemsList = [
        {
            option: 'Projects',
            onClick: () => {
                setPrjectsSubListSelected(true);
            }
        },
        { option: 'Sign out', onClick: handleSignout }
    ];

    const projectItemsList = useMemo(() => {
        if (isLoading) {
            return [
                {
                    option: '<',
                    onClick: () => {
                        setPrjectsSubListSelected(false);
                    }
                },
                { option: 'Loading', onClick: () => {} }
            ];
        }
        const projects = (repositories || []).map((r) => r.project);
        const unique_project_ids = [...new Set(projects.map((p) => p.id))];
        const unique_projects = unique_project_ids.map(
            (id) => projects.filter((p) => p.id === id)[0]
        );

        return [
            {
                option: '<',
                onClick: () => {
                    setPrjectsSubListSelected(false);
                }
            },
            ...unique_projects.map((project) => ({
                option: project.name,
                onClick: () => {
                    dispatch(selectProject(project.id)).then(() => {
                        dispatch(fetchProjectWorkTimeReview());
                        dispatch(
                            fetchProjectQuests({
                                page: 0,
                                resultsPerPage: questsRowsPerPage,
                                q: questsSearchQuery,
                                statusFilters: questsStatusFilter || []
                            })
                        );
                    });
                },
                selected: selectedProjectId === project.id
            }))
        ];
    }, [
        repositories,
        selectedProjectId,
        dispatch,
        isLoading,
        questsRowsPerPage,
        questsSearchQuery,
        questsStatusFilter
    ]);

    useEffect(() => {
        if (!repositories) {
            dispatch(fetchClientRepositories())
                .unwrap()
                .then((result) => {
                    if (result) {
                        const projectId = result.data[0].project.id;
                        dispatch(selectProject(projectId));
                    }
                })
                .catch(() => {});
        }
        dispatch(fetchTemplates());
    }, [dispatch, repositories]);

    return (
        <Box sx={{ display: 'flex' }}>
            <StyledAppBar>
                <StyledToolbar>
                    <Link to="https://app.caas.ai/">
                        <AppLogo />
                    </Link>
                    <Link
                        to={RoutesEnum.CLIENT_DASHBOARD}
                        style={{
                            textDecoration: 'none',
                            color:
                                location === RoutesEnum.CLIENT_DASHBOARD
                                    ? theme.color.lightningYellow
                                    : '#FFF'
                        }}
                    >
                        Overview
                    </Link>
                    {admin && (
                        <Link
                            to={RoutesEnum.PROJECT_DASHBOARD}
                            style={{
                                textDecoration: 'none',
                                color:
                                    location === RoutesEnum.PROJECT_DASHBOARD
                                        ? theme.color.lightningYellow
                                        : '#FFF'
                            }}
                        >
                            Projects
                        </Link>
                    )}
                    {admin && (
                        <Link
                            to={RoutesEnum.COMMUNITY}
                            style={{
                                textDecoration: 'none',
                                color:
                                    location === RoutesEnum.COMMUNITY
                                        ? theme.color.lightningYellow
                                        : '#FFF'
                            }}
                        >
                            Community
                        </Link>
                    )}
                    <Link
                        to={RoutesEnum.DELEGATE_TASK}
                        style={{
                            textDecoration: 'none',
                            color:
                                location === RoutesEnum.DELEGATE_TASK
                                    ? theme.color.lightningYellow
                                    : '#FFF'
                        }}
                    >
                        Delegate
                    </Link>
                    <Link
                        to={RoutesEnum.TEMPLATES}
                        style={{
                            textDecoration: 'none',
                            color:
                                location === RoutesEnum.TEMPLATES
                                    ? theme.color.lightningYellow
                                    : '#FFF'
                        }}
                    >
                        Templates
                    </Link>
                    <DropdownWithIcon
                        icon={<PersonIcon />}
                        list={
                            prjectsSubListSelected
                                ? projectItemsList
                                : menuItemsList
                        }
                    />
                </StyledToolbar>
            </StyledAppBar>
            <Box
                component="main"
                sx={{
                    minWidth: '100%',
                    minHeight: '100vh',
                    backgroundColor: theme.color.codGray
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default Navbar;
