import { FunctionComponent } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TooltipWrapper, tableStyles } from 'src/shared/Table/styled';
import { Label } from 'src/shared';

export interface Props {}

const Requests: FunctionComponent<Props> = ({}: Props) => {
    return (
        <TableHead sx={tableStyles.headRow}>
            <TableRow sx={tableStyles.dataRow}>
                <TableCell
                    sx={{
                        ...tableStyles.muiTableCell,
                        ...tableStyles.headCell,
                        ...tableStyles.rowView
                    }}
                >
                    <Label component="span" sxOverrides={tableStyles.baseLabel}>
                        Title
                    </Label>
                </TableCell>
                <TableCell
                    sx={{
                        ...tableStyles.muiTableCell,
                        ...tableStyles.headCell,
                        ...tableStyles.rowView
                    }}
                >
                    <Label component="span" sxOverrides={tableStyles.baseLabel}>
                        Date Created
                    </Label>
                </TableCell>
                <TableCell
                    sx={{
                        ...tableStyles.muiTableCell,
                        ...tableStyles.headCell,
                        ...tableStyles.rowView
                    }}
                >
                    <TooltipWrapper
                        title={
                            <Label sxOverrides={{ color: 'white' }}>
                                Net times takes up to 48 hours to update, it
                                does not include QA and tech lead time.
                            </Label>
                        }
                        arrow
                    >
                        <div>
                            <Label
                                component="span"
                                sxOverrides={tableStyles.baseLabel}
                            >
                                Net Contributors Time
                            </Label>
                        </div>
                    </TooltipWrapper>
                </TableCell>
                <TableCell
                    sx={{
                        ...tableStyles.muiTableCell,
                        ...tableStyles.headCell,
                        ...tableStyles.rowView
                    }}
                >
                    <Label component="span" sxOverrides={tableStyles.baseLabel}>
                        Status
                    </Label>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default Requests;
