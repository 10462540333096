import { FC, useState, useEffect, useCallback } from 'react';
import { RatingBox } from './styled';
import { Star, StarOutline } from '@mui/icons-material';
import { Title, Subtitle } from 'src/shared';
import color from 'src/theme/color';
import { Rating } from 'src/types/rating';
import { RatingSubject } from 'src/enums/rating';
import { Rating as MuiRating } from '@mui/material';

interface RatingItemProps {
    question: string;
    onChange: (event: React.ChangeEvent<{}>, value: number | null) => void;
}

const RatingItem: FC<RatingItemProps> = ({ question, onChange }) => (
    <RatingBox>
        <Subtitle>{question}</Subtitle>
        <MuiRating
            icon={<Star />}
            emptyIcon={<StarOutline style={{ color: color.white50 }} />}
            onChange={onChange}
        />
    </RatingBox>
);

interface StarsRatingProps {
    onRatingsComplete: (ratings: Rating[]) => void;
}

const StarsRating: FC<StarsRatingProps> = ({ onRatingsComplete }) => {
    const [ratings, setRatings] = useState<Rating[]>([]);

    const codeQualityQuestion = {
        subject: RatingSubject.QUEST_SOLUTION_CODE_QUALITY,
        text: 'How would you rate the request solution code quality?'
    };

    const requirementsMatchQuestion = {
        subject: RatingSubject.QUEST_SOLUTION_REQUIREMENTS_MATCH,
        text: 'How would you rate the request solution match requirements?'
    };

    const handleRatingChange = useCallback(
        (rating: Rating) => {
            setRatings((prevRatings) => {
                const existingRatingIndex = prevRatings.findIndex(
                    (r) => r.subject === rating.subject
                );
                let updatedRatings;

                if (existingRatingIndex !== -1) {
                    // Update the existing rating
                    updatedRatings = [...prevRatings];
                    updatedRatings[existingRatingIndex] = rating;
                } else {
                    // Add the new rating
                    updatedRatings = [...prevRatings, rating];
                }

                return updatedRatings;
            });
        },
        [setRatings]
    );

    useEffect(() => {
        const hasCodeQualityRating = ratings.some(
            (r) => r.subject === codeQualityQuestion.subject
        );
        const hasRequirementsMatchRating = ratings.some(
            (r) => r.subject === requirementsMatchQuestion.subject
        );

        if (hasCodeQualityRating && hasRequirementsMatchRating) {
            onRatingsComplete(ratings);
        }
    }, [
        ratings,
        codeQualityQuestion.subject,
        requirementsMatchQuestion.subject,
        onRatingsComplete
    ]);

    return (
        <div style={{ textAlign: 'center' }}>
            <Title>Request rating</Title>
            <RatingItem
                question={codeQualityQuestion.text}
                onChange={(event, value) =>
                    handleRatingChange({
                        subject: codeQualityQuestion.subject,
                        score: value || 0,
                        text: codeQualityQuestion.text
                    })
                }
            />
            <RatingItem
                question={requirementsMatchQuestion.text}
                onChange={(event, value) =>
                    handleRatingChange({
                        subject: requirementsMatchQuestion.subject,
                        score: value || 0,
                        text: requirementsMatchQuestion.text
                    })
                }
            />
        </div>
    );
};

export default StarsRating;
